<script setup>
// import { useAppStore } from '@/stores/App'
import { useCartStore } from '@/stores/Cart'

const staticURLImg = useRuntimeConfig().public.staticURLImg
// const app = useAppStore()
const cart = useCartStore()
const { formatUSD } = useFormatters()

// Props for product
defineProps({
    iconSize: {
        type: Number, // product thumbnail size
        default: 48,
    },
    isEditable: {
        type: Boolean, // when true, remove product from cart button appears
        default: false,
    },
})

function currentPrice(item) {
    //console.log('item = ', item)
    if (item.productPrices.totalCount > 0) {
        return item.productPrices.edges[0].node.price
    } else {
        return item.price
    }
}
</script>

<template>
    <v-row id="cartitem">
        <v-col cols="12" class="d-flex flex-nowrap pt-2">
            <span class="overline font-weight-bold">Product</span>
            <v-spacer></v-spacer>
            <span class="overline font-weight-bold">Price</span>
        </v-col>
        <v-divider></v-divider>
        <v-col v-for="item in cart.items" :key="item.id" cols="12" class="d-flex justify-space-between align-center">
            <app-button
                v-if="isEditable"
                icon
                :variant="`text`"
                :btn-icon="`mdi-close-circle`"
                :show-tooltip="true"
                :tooltip-text="`Remove`"
                @click.stop="cart.removeProduct(item)"
                aria-label="Remove product from cart"
            />
            <v-avatar :size="iconSize" class="rounded elevation-1">
                <img
                    :src="
                        item.productImages.totalCount > 1
                            ? staticURLImg + item.productImages?.edges[1]?.node.source
                            : staticURLImg + item.productImages?.edges[0]?.node.source
                    "
                    alt="Product thumbnail"
                />
            </v-avatar>
            <v-row v-if="isEditable" class="d-flex flex-column">
                <v-col class="ml-2 pb-0 font-weight-medium">{{ item.name }}</v-col>
                <v-col class="ml-2 pt-0 text-caption font-weight-medium">{{ item.tagline }}</v-col>
            </v-row>
            <v-row v-else>
                <v-col class="ml-2">{{ item.name }}</v-col>
            </v-row>
            <v-spacer></v-spacer>
            <span class="font-weight-medium">
                {{ formatUSD(currentPrice(item)) }}
            </span>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12" class="text-right font-weight-bold">
            <span class="mr-4">Total: </span> {{ formatUSD(cart.totalAmount) }}
        </v-col>
    </v-row>
</template>

<style scoped></style>
